import React from "react";

const Hero: React.FC = () => {
  return (
    <section id="home" className="hero">
      <h1>Spatial and Behavioral AI</h1>
      <p>
        Leading Sensing AI platform for privacy-preserving human behavioral
        sensing in physical space
      </p>
      <a
        href="https://app.sense-ai.org/AIoT%20Office/realtime"
        className="cta-button"
      >
        Request Demo
      </a>
    </section>
  );
};

export default Hero;
